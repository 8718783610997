import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';
import Button from './button';
import Title from './title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:500px;
`;

const ColoredSeparator = styled.div`
  width: 80%;
  height: 2px;
  background-color: #f1ece9;
  margin-top: 12px;
  margin-bottom: 2vh;
`;

const EmptyCheckout = () => {

  const onClick = () => navigate("/products");

  return (
    <Container>
      <Title>Votre panier est vide</Title>
      <ColoredSeparator />
      <Button onClick={onClick}>Continuer mes achats</Button>
    </Container>
  )
};

export default EmptyCheckout;