import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout'
import Button from '../components/button';
import { useCheckout } from '../redux/selectors';
import Title from '../components/title';
import EmptyCheckout from '../components/empty-checkout';
import { getStore } from '../redux/store';
import { removeFromCheckout } from '../redux/actions';
import { Link } from 'gatsby';
import Meta from '../components/meta';
import { useDispatch } from 'react-redux';

const SuperContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Container = styled.div`
  width: 500px;
  max-width:100vw;
`;

const Item = styled.div`
  display: flex;
  width: 500px;
  max-width: 100vw;
`;

const ItemImage = styled.img`
  width: 150px;
`;

const ItemInformations = styled.div`
  margin-left: 24px;
`;

const ItemTitle = styled.div`
  font-family: 'GLECB', 'GLECB1';
  font-size: 2rem;
`;

const ItemSize = styled.div``;

const ItemQuantity = styled.div``;

const ItemPrice = styled.div`
  font-family: 'GLECB', 'GLECB1';
  font-size: 2rem;
  margin-left: 24px;
`;

const ItemDelete = styled.div`
  background: linear-gradient(currentColor 0 0) 0 100% /var(--d, 0) 1px no-repeat;
  transition: 0.5s;
  cursor: pointer;
  :hover {
    --d: ${props => props.children.length}ch;
  }
`;

// TODO WARNING THIS IS DUPLICATE
const ColoredSeparator = styled.div`
  width: 80%;
  height: 2px;
  background-color: #f1ece9;
  margin-top: 12px;
  margin-bottom: 2vh;
  margin-left: 10%;
`;

const ItemsContainer = styled.div`
  max-height: 54vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 768px) {
    max-height: none;
    overflow-y: none;
    overflow-x: none;
  }
`;

const CustomTitle = styled(Title)`
  text-align: center;
`;

const PricesContainer = styled.div`
  font-family: 'GLECB', 'GLECB1';
  font-size: 1.5rem;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
`;

const PriceLabel = styled.div``;
const Total = styled.div``;

const BackToProductsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

const BackToProducts = styled(Link)`
  color: black;
  text-decoration: none;
  border: 1px solid white;

  &:hover {
    border-bottom-color: black;
    color: black;
  }
`;

const CheckoutPage = () => {
  const checkout = useCheckout();
  const dispatch = useDispatch();

  if (!checkout) {
    return null;
  }

  const redirect = () => {
    window.location.replace(checkout.webUrl);
  }

  const deleteItem = (shopifyId) => () => {
    dispatch(removeFromCheckout(shopifyId))
  }

  const products = checkout.lineItems.map(item => {
    return {
      shopifyId: item.id,
      name: item.title,
      quantity: item.quantity,
      variant: {
        id: item.variant.id,
        price: item.variant.price,
        name: item.variant.title,
        image: item.variant.image.src,
      },
      price: item.variant.price * item.quantity,
    }
  });

  if (products.length === 0) {
    return (
      <Layout>
        <Meta title="Votre panier est vide"/>
        <ColoredSeparator />
        <SuperContainer>
          <EmptyCheckout />
        </SuperContainer>
      </Layout>);
  }

  const productsPrice = checkout.subtotalPrice;
  const shippingPrice = checkout.shippingLine ? checkout.shippingLine.price : 0; // TODO investigate
  const total = checkout.totalPrice;

  return (
    <Layout>
      <Meta title="Votre panier"/>
      <ColoredSeparator />
      <SuperContainer>
        <Container>
          <CustomTitle>Résumé de la commande</CustomTitle>
          <ColoredSeparator />
          <ItemsContainer>
            {products.map((item, index, array) =>
              <React.Fragment key={index}>
                <Item>
                  <ItemImage src={item.variant.image} />
                  <ItemInformations>
                    <ItemTitle>{item.name}</ItemTitle>
                    <ItemSize>Taille: {item.variant.name}</ItemSize>
                    <ItemQuantity>Qté: {item.quantity}</ItemQuantity>
                    <ItemDelete onClick={deleteItem(item.shopifyId)}>Supprimer</ItemDelete>
                  </ItemInformations>
                  <ItemPrice>{item.price}€</ItemPrice>
                </Item>
                {index + 1 !== array.length && <ColoredSeparator />}
              </React.Fragment>
            )}
          </ItemsContainer>
          <ColoredSeparator />
          <PricesContainer>
            <Price>
              <PriceLabel>Total</PriceLabel>
              <Total>{productsPrice}€</Total>
            </Price>
            <Price>
              <PriceLabel>Livraison</PriceLabel>
              <Total>{shippingPrice}€</Total>
            </Price>
            <ColoredSeparator />
            <Price>
              <PriceLabel>Total TTC</PriceLabel>
              <Total>{total}€</Total>
            </Price>
          </PricesContainer>
          <ColoredSeparator />
          <Button onClick={redirect}>Passer commande</Button>
          <BackToProductsContainer>
            <BackToProducts to="/products">Continuer mes achats</BackToProducts>
          </BackToProductsContainer>
        </Container>
      </SuperContainer>
    </Layout>
  );
}

export default CheckoutPage;